<template>
<b-overlay
  :show="loading"
  rounded="lg">
  <b-breadcrumb style="margin-bottom:20px">
    <b-breadcrumb-item to="/">
      <feather-icon
        icon="HomeIcon"
        size="16"
        class="align-text-top" />
    </b-breadcrumb-item>
    <b-breadcrumb-item
      to="/manual-return-delivery/daikin"
      v-if="goBack === 'manual-return-daikin'">List Manual Return Daikin</b-breadcrumb-item>
    <b-breadcrumb-item
      to="/manual-return-delivery/sumitomo"
      v-if="goBack === 'manual-return-sumitomo'">List Manual Return Sumitomo</b-breadcrumb-item>
    <b-breadcrumb-item active>Manual Return Delivery Order e-Form</b-breadcrumb-item>
  </b-breadcrumb>
  <b-card title="Manual Return eForm">
    <b-modal
      v-model="modalSkip"
      title="Return Item">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div
              class="d-flex"
              v-if="selectData.skip === true">
              Are you sure want to return this
              {{ getItem(selectData.material).itemModel }} item?
            </div>
            <div
              class="d-flex"
              v-if="selectData.skip === false">
              Are you sure want to return this
              {{ selectBarcode.barcode }} item?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button
            variant="success"
            class="ml-1"
            @click="verifyReturn">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="modalRemove"
      title="Remove Return Item">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div
              class="d-flex"
              v-if="selectData.skip === true">
              Are you sure want to remove this
              {{ getItem(selectData.material).itemModel }} item from return list?
            </div>
            <div
              class="d-flex"
              v-if="selectData.skip === false">
              Are you sure want to remove this
              {{ (selectBarcode.skip)?"Skip Scan":selectBarcode.barcode }} item from return list?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button
            variant="success"
            class="ml-1"
            @click="remove">Confirm</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="modalRequest"
      title="Request Return Delivery">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              Are you sure want to send request return this delivery order?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button
            variant="success"
            class="ml-1"
            @click="sendAcknowledgment">Confirm</b-button>
        </div>
      </template>
    </b-modal>

    <b-col
      cols="12"
      style="margin-bottom:20px;">
      <b-row>
        <b-button
          variant="primary"
          @click="handleMainMenuClick">
          <feather-icon
            size="16"
            icon="HomeIcon" /> Main Menu</b-button>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      v-if="pageone">
      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>SAP DO Number</label>
            <p class="font-weight-bold">{{ doData.sapDo }}</p>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Delivery Address</label>
            <p class="font-weight-bold">
              {{ doData.deliveryAddress1 }}
            </p>
            <p class="font-weight-bold">
              {{ doData.deliveryAddress2 }}
            </p>
            <p class="font-weight-bold">
              {{ doData.deliveryAddress3 }}
            </p>
            <p class="font-weight-bold">{{ doData.deliveryCity }}</p>
            <p class="font-weight-bold">
              {{ doData.deliveryPostalCode }}
            </p>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Business Partner</label>
            <p class="font-weight-bold">{{ doData.CustomerName1 +" "+doData.CustomerName2 }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Customer Information</label>
            <p class="font-weight-bold">{{ doData.CustomerName3 }}</p>
            <p class="font-weight-bold">{{ doData.phone }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Original Shipping Point</label>
            <p class="font-weight-bold">{{ doData.shippingPoint == '1001'? 'Daikin Warehouse' : 'Sumitomo Warehouse' }}</p>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex flex-column">
            <label>Return Shipping Point</label>
            <p class="font-weight-bold">{{ goBack == 'manual-return-daikin' ? 'Daikin Warehouse' : 'Sumitomo Warehouse' }}</p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="mb-2 mt-2"
          md="4">
          <label>Reason Return Item <span style="color:red">(*)</span></label>
          <!-- <b-form-textarea
              @input="reasonState"
              v-model="reasonReturn"
              id="textarea"
              placeholder="Enter reason of return..."
              rows="3"
              max-rows="6"
            ></b-form-textarea> -->
          <b-form-select
            v-model="reasonReturn"
            :options="reasonRetunFields"></b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-table
          :fields="scanFields"
          :items="doData.DO_details"
          show-empty
          responsive
          :stacked="stackedStatus">
          <template #cell(actions)="row">
            <b-button
              @click="goDetail(row.item)"
              variant="primary">Select</b-button>
          </template>
          <template #cell(material)="row">
            {{ getItem(row.item.material).itemModel }}
          </template>
          <template #cell(desc)="row">
            {{ getItem(row.item.material).description }}
          </template>
          <template #cell(qty)="row">
            <div v-if="row.item.qtyUpdate">
              {{ row.item.qtyUpdate }}
            </div>
            <div v-else>
              {{ row.item.qty }}
            </div>
          </template>
          <template #cell(return)="row">
            {{ getSummary(row.item.material).scanQty }}
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-button
            @click="modalRequest = true"
            class="w-100 d-flex justify-content-center"
            :disabled="showRequest"
            variant="success">Request Return Approval</b-button>
        </b-col>
        <b-col md="8" />
      </b-row>
    </b-col>
    <b-col v-if="pagetwo">
      <b-row class="mb-2 mt-3 desktop-version">
        <b-col cols="6">
          <b-form-input
            v-model="searchKeyword"
            placeholder="Search by barcode"
          ></b-form-input>
        </b-col>
        <b-col cols="6">
          <b-button
          class="mr-1"
            @click="performSearch()"
            variant="primary">Search
          </b-button>
          <b-button
            @click="searchedKeyword = ''"
            variant="secondary">Clear Search
          </b-button>
          </b-col>
          <b-col>
        </b-col>
      </b-row>
      <b-row class="mb-1 mt-3 mobile-version">
        <b-col>
          <b-form-input
            v-model="searchKeyword"
            placeholder="Search by barcode"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mb-2 mobile-version">
        <b-col cols="6">
          <b-button
          class="mr-1"
            @click="performSearch()"
            variant="primary">Search
          </b-button>
        </b-col>
        <b-col  cols="6">
          <b-button
            @click="searchedKeyword = ''"
            variant="secondary">Clear Search
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          :fields="itemFields"
          :items="filterItem"
          show-empty
          responsive
          :stacked="stackedStatus">
          <template #cell(status)="row">
            <strong v-if='row.item.warrantyStatus=="Registered"'>Registered</strong>
            <strong v-else-if="availability(row.item,row.index)">Not Selected</strong>

            <strong v-else>Selected</strong>

          </template>
          <template #cell(actions)="row">
            <strong v-if='row.item.warrantyStatus=="Registered"'></strong>
            <b-button
              @click="selectItem(row.item)"
              v-else-if="availability(row.item,row.index)"
              variant="primary">+</b-button>
            <b-button
              v-else
              variant="danger"
              @click="remove(row.item)">
              <feather-icon
                icon="XIcon"
                size="16"
                class="align-text-top">
              </feather-icon>
            </b-button>
          </template>

          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>

          <template #cell(barcode)="row">
            <span v-if="row.item.skip === true">Skip Scan</span>
            <span v-if="row.item.skip === false">{{ row.item.barcode }}</span>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-button
            @click="backPage"
            class="w-100 d-flex justify-content-center"
            variant="primary">Back</b-button>
        </b-col>
        <b-col md="8"> </b-col>
      </b-row>
    </b-col>
  </b-card>
</b-overlay>
</template>

<script>
import {
  mapActions
} from "vuex";
import axios from "@/axios";
import {
  ValidationProvider,
  ValidationObserver
} from "vee-validate";
import {
  required,
  email
} from "@validations";
import {
  dateFormat
} from "@/utils/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["id", "from"],
  data() {
    return {
      stackedStatus: "md",
      //for search
      searchKeyword: '',
      searchedKeyword: '',
      doData: {},
      scanItemTables: [],
      scanFields: [{
          key: "material",
          label: "Item Model"
        },
        {
          key: "desc",
          label: "Description"
        },
        {
          key: "qty",
          label: "Qty"
        },
        {
          key: "return",
          label: "Return"
        },
        {
          key: "actions",
          label: "Action"
        },
      ],
      itemFields: [{
          key: "index",
          label: "NO"
        },
        {
          key: "barcode",
          label: "Barcode"
        },
        {
          key: "status",
          label: "Status"
        },
        {
          key: "actions",
          label: "Add To Return"
        },
      ],
      reasonRetunFields: [{
          value: "R01 - Customer orders wrong model or qty",
          text: "R01 - Customer orders wrong model or qty",
        },
        {
          value: "R04 - Unit faulty",
          text: "R04 - Unit faulty"
        },
        {
          value: "R06 - Deliver wrong model or qty",
          text: "R06 - Deliver wrong model or qty",
        },
        {
          value: "R07 - Deliver at wrong date",
          text: "R07 - Deliver at wrong date",
        },
        {
          value: "R14- Sales Admin entry error",
          text: "R14- Sales Admin entry error",
        },
      ],
      warehouseOption: [{
          value: "1001",
          text: "Daikin Warehouse",
        },
        {
          value: "1003",
          text: "Sumitomo Warehouse",
        },
      ],
      items: [],
      pageone: true,
      pagetwo: false,
      modalSkip: false,
      modalRequest: false,
      modalRemove: false,
      selectData: "", //for select item list at DO
      selectBarcode: "", //for select item detail list after scan
      flagRemove: true, // untuk menandai jika manualReturnFlag pada item tersebut bisa di remove
      DO_details: "",
      forCount: "",
      postal: [],
      itemCatalog: [],
      itemInventory: [],
      reasonReturn: "",

      salesOrder: [],
      goBack: "", //this one variable to mark this page is opened from what table list?
      shippingPoint: "",
      loading: false
    };
  },
  created() {
    this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
        this.dataPopulate(Detail);
        axios
          .get("sales-and-purchase/inventory/search", {
            params: {
              sapDo: this.doData.sapDo,
              entry:10000,page:1
            }
          })
          .then((response) => {
            this.itemInventory = response.data.data;
            // console.log(this.filterItem)
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((err) => {
        console.log({
          err
        });
      });
  },
  mounted() {
    this.goBack = this.$route.params.from;

    if (this.goBack == 'manual-return-daikin') {
      this.shippingPoint = '1001'
    }
    if (this.goBack == 'manual-return-sumitomo') {
      this.shippingPoint = '1003'
    }
    //temporary get api sales from axios
    axios
      .get("sales-and-purchase/sales/drcc", {
        params: {
          orderStatus: "ALL"
        }
      })
      .then((response) => {
        this.salesOrder = response.data.data;
        // console.log(this.salesOrder)
      })
      .catch((e) => {
        console.log(e);
      });
    //temporary get api items catalog from axios
    /*
    axios
      .get("sales-and-purchase/drcc/items?total=100")
      .then((response) => {
        this.itemCatalog = response.data.data;
        // console.log(this.itemCatalog)
      })
      .catch((e) => {
        console.log(e);
      });
    */
    //temporary get api items inventory from axios

    //temporary get api post code from axios
    /*
        axios.get('sales-and-purchase/drcc/postal').then((response) => {
            this.postal = response.data.data;
        }).catch((e) => {
            console.log(e)
        })
        */
  },
  methods: {
    ...mapActions({
      updateDelivery: "delivery/updateDO",
      sendManualReturnToFTP: "delivery/sendManualReturnToFTP"
    }),
    getItems() {
      var idList = this.doData.DO_details.map(x => {
        return x.material
      });
      //temporary get api items catalog from axios
      var list = idList.join("|")
      //console.info('getItems list ',list)
      axios
        .get("sales-and-purchase/drcc/items/multi?idList=" + list)
        .then((response) => {
          this.itemCatalog = response.data.data;
          // console.log(this.itemCatalog)
        })
        .catch((e) => {
          console.log(e);
        });
    },
    dataPopulate(Detail) {
      let tableIndex = 0;
      //ini tuh buat list scan Item per barcode baik yang scan atau yang retur
      this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
        let expandedQty = [];
        var barcodeDO = next.scannedBarcode.map((x) => {
          return x.itemUsed;
        });
        var unscanBarcodeDO = next.returnBarcode.map((x) => {
          return x.item;
        });
        for (let i = 0; i < next.qty; i++) {
          var tempBarcode, tempUnScan;

          //for original scan Barcode
          if (barcodeDO[i] === undefined) {
            tempBarcode = "";
          } else {
            tempBarcode = barcodeDO[i];
          }

          //for list return barcode
          if (unscanBarcodeDO[i] === undefined) {
            tempUnScan = "";
          } else {
            tempUnScan = unscanBarcodeDO[i];
          }
          expandedQty.push({
            ...next,
            scannedBarcode: tempBarcode,
            returnBarcode: tempUnScan,
            qty: 1,
            index: tableIndex,
          });
          tableIndex++;
        }

        return [...prev, ...expandedQty];
      }, []);

      // ini mapping data delivery detail
      this.doData = Detail;
      this.getItems();
    },
    performSearch(){
      this.searchedKeyword = this.searchKeyword
    },
    dates(date) {
      return dateFormat(date);
    },
    goDetail(data) {
      //ini dari page 1 ke page 2 saat pilih material
      this.pageone = false;
      this.pagetwo = true;
      this.selectData = data;
    },
    backPage() {
      //ini dari page 2 ke page 1
      this.pageone = true;
      this.pagetwo = false;
      this.selectData = "";
    },
    availability(data, index) {
      //availability for skip scan
      if (data.skip) {
        let dataMaterial = this.doData.DO_details.filter((item) => {
          let materialIsRight = (item.material === data.itemCatalogue)
          return materialIsRight
        })
        let gap = dataMaterial[0].qty - dataMaterial[0].returnBarcode.length
        if (index < gap) {
          return true
        } else {
          return false
        }
      } else {
        //ini buat tampilan button waktu pilih item yg akan di retur
        //only fr non skip scan barcode
        var result;
        let scanIndex = this.scanItemTables.findIndex((item) => {

          let materialIsRight = (item.material === data.itemCatalogue)
          return (
            (item.returnBarcode === data.barcode ||
              (data.skip && item.returnBarcode == "Skip Scan") // to handle return barcode for skip scan
            ) &&
            materialIsRight
          );
        });
        // console.log("index",scanIndex);
        // console.log(data);

        if (scanIndex < 0) {
          result = true;
        } else {
          result = false;
        }
        // console.log(result);
        return result;
      }

    },
    verifyReturn() {
      //ini buat konfirmasi barcode yang akan di retur

      //console.log(this.selectBarcode)
      this.loading = true

      axios
        .post("sales-and-purchase/drcc/delivery/return", {
          barcode: this.selectBarcode.barcode,
          material: this.selectBarcode.itemCatalogue,
          shippingPoint: this.shippingPoint,
          idDO: this.id,
          isReturnManual: true
        })
        .then((response) => {
          // let item = response.data.data;
          this.loading = false

          var updateDOData = response.data.data;
          this.dataPopulate(updateDOData);
          //filter by qty req per item

          this.$bvToast.toast("Success Add Item to Return", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.modalSkip = false;
        })
        .catch((e) => {
          this.loading = false
          if (e.response.data !== undefined) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Add Item to return failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Connection error. Please refresh the page and try again", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });

    },

    selectItem(data) {
      //ini waktu klik button yg pilih inventory atau barcode mana yg akan di retur. pengecekan POS code juga ada atau tidak
      //var searchPostcode = this.postal.find(x=>{return x.itemModel==data.itemCatalogue});

      //if(searchPostcode!=undefined){

      this.selectBarcode = data;
      this.modalSkip = true;
      /*
            }else{
                this.$bvToast.toast('This Item does not have Post Code', {
                title: "Error",
                variant: "danger",
                solid: true,
                });
            }
            */
    },
    async sendAcknowledgment() {

      //untuk mengisi flag manual return
      var manualFlag
      if (this.doData.manualReturnFlag == undefined || this.doData.manualReturnFlag == '' || this.doData.manualReturnFlag == 0) {
        manualFlag = 1
      } else {
        manualFlag = this.doData.manualReturnFlag + 1
      }

      //ini waktu input data

      this.loading = true;
      let requestReturn = {
        DO_details: this.doData.DO_details.map((x)=>{
          return {material:x.material,returnBarcode:x.returnBarcode}
        }),
        mark: "Return Delivery",
        status: "Waiting Acknowledgment",
        reason: this.reasonReturn,
        shippingPoint: this.shippingPoint,
        manualReturnFlag: manualFlag,
        ftpReturnSent: false
      };
      //console.log("requestReturn", requestReturn);
      //console.log("this.doData.DO", this.doData.DO_details);
      /**
            THIS PART BELOW IS NOT EVEN USED, WHY EVEN BOTHER??
            */

      //This part doesn't make sense performance wise, but it will do the trick
      /*
            let preBarcode = []; //list of all scanned barcode (list of item to be change to PRE, to enable warranty registration)
            let returnBarcode =[]; //list of all returned barcode (list of item to NOT be change to PRE, warranty)
            let mapPreBarcode = this.doData.DO_details.filter(x=>{return x.skip===false}).map(y=>{return {scannedBarcode:y.scannedBarcode,returnBarcode:y.returnBarcode}})
            for(var i=0;i<mapPreBarcode.length;i++){
                let curMap= mapPreBarcode[i];
                let curPre= curMap.scannedBarcode.map(x=>{return x.itemUsed});
                let curReturn = curMap.returnBarcode.map(x=>{return x.item});
                preBarcode= preBarcode.concat(curPre)
                returnBarcode = returnBarcode.concat(curReturn)
            }

            //removes returned barcode from pre barcode
            preBarcode = preBarcode.filter(val => !returnBarcode.includes(val));
            */

      var updateStatus;
      var inventoryStatus;

      try {
        inventoryStatus = await axios.post(
          "sales-and-purchase/drcc/inventory/return-warranty", {
            sapDo: this.doData.sapDo,
          }
        );
      } catch (err) {
        this.modalRequest = false;
        this.loading = false;
        console.error(err);
        if (err.response.data !== undefined) {
          this.$bvToast.toast(err.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Connection error. Please refresh the page and try again", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
        return;
      }

      try {
        updateStatus = await this.updateDelivery({
          idDelivery: this.id,
          deliveryData: requestReturn,

        });
      } catch (err) {
        this.modalRequest = false;
        this.loading = true;
        if (err.response.data !== undefined) {
          this.$bvToast.toast(err.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Connection error. Please refresh the page and try again", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
        return;
      }

      this.$bvToast.toast("Success to Request Return DO", {
        title: "Success",
        variant: "success",
        solid: true,
      });
      var ftpReturn;

      if (this.shippingPoint === "1001") {
        this.$router.push({
          path: "/return-delivery/daikin/",
          query: {
            status: "Waiting Acknowledgement"
          },
        });
      }
      if (this.shippingPoint === "1003") {
        this.$router.push({
          path: "/return-delivery/sumitomo/",
          query: {
            status: "Waiting Acknowledgement"
          },
        });
      }
    },
    selectItemRemove(data) {
      //ini waktu klik button yg pilih inventory atau barcode mana yg akan di retur. pengecekan POS code juga ada atau tidak
      //var searchPostcode = this.postal.find(x=>{return x.itemModel==data.itemCatalogue});

      //if(searchPostcode!=undefined){

      this.selectBarcode = data;
      this.modalRemove = true;
    },
    remove(data) {
      this.selectBarcode = data;
      this.loading = true
      const payload = {
        // idDo : this.id,
        _id: this.id,
        barcode: this.selectBarcode.barcode,
        material: this.selectBarcode.itemCatalogue,
        isReturn: true
      };
      // this.tempRemoveData = payload;
      this.xStatus = true;

      axios
        .post("sales-and-purchase/drcc/delivery/remove", payload)
        .then((response) => {
          this.loading = false
          var updateDOData = response.data.data;
          this.dataPopulate(updateDOData);
          //filter by qty req per item

          this.$bvToast.toast("Success Remove Return Item", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.modalRemove = false;
        })
        .catch((err) => {
          this.loading = false
          if (err.response.data !== undefined) {
            this.$bvToast.toast(err.response.data.message, {
              title: "Add Item to return failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Connection error. Please refresh the page and try again", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
          console.log({
            err
          });
        });
    },
    //ini buat cari jumlah discan dan belum discan berdasarkan material id yg dicari
    getSummary(material) {
      let itemSummary = this.summary[material];
      if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0}
      }
      return itemSummary;
    },
    //ini buat dapet data item katalog berdasarkan id material yg ada di DO_details
    getItem(data) {
      var searchItem = this.itemCatalog.find((x) => {
        return x._id == data;
      });
      var result;
      if (searchItem === undefined) {
        result = {
          itemModel: " ",
        };
      } else {
        result = searchItem;
      }
      // console.log (result);
      return result;
    },

    //method untuk handle button main menu ketika klik
    async handleMainMenuClick() {
      // Continue with navigation
      this.$router.go(-1);
    },
  },
  computed: {
    filterItem() {
      //ini untuk menampilkan list inventory apa saja yang terkait dengan retur barcode ini
      //console.log('this.shippingPoint', this.shippingPoint);
      var item = this.getItem(this.selectData.material).itemModel;
      //console.log("item", item);
      //console.log("this.doData.sapDo", this.doData.sapDo);
      var result = this.itemInventory.filter(
        (x) => {
          return x.sapDo === this.doData.sapDo && x.itemModel === item && x.scanStatus == "Scan"
        }
      );

      if(this.searchedKeyword){
        result = result.filter((item) => {
          return item.barcode == this.searchedKeyword
        })
      }


      //console.log("result", result);
      return result;
    },
    summary() {
      //ini tuh buat itung ada berapa yang udah di scan atau belum
      return this.scanItemTables.reduce((prev, next) => {
        // console.log("ini prev", prev);
        // console.log("ini next", next);

        if (prev[next.material] === undefined) {
          prev[next.material] = {
            scanQty: 0,
            emptyQty: 0
          };
        }

        if (next.returnBarcode === "") {
          prev[next.material].emptyQty += 1;
        } else {
          prev[next.material].scanQty += 1;
        }
        return prev;
      }, {});
    },
    showRequest() {
      var tempSelected = [];
      if (this.doData.DO_details == undefined) {
          return true;
      }
      let manualFlag = 0
      if(this.doData.manualReturnFlag){
        manualFlag = this.doData.manualReturnFlag
      }

      // Memeriksa apakah ada item memiliki flag yang sesuai
      var hasFlagMatch = this.doData.DO_details.some((element) => {
          return element.returnBarcode.some((barcode) => {
              return barcode.flag === (manualFlag + 1);
          });
      });

      // Jika tidak ada yang cocok dan alasan return dipilih, maka tombol dinonaktifkan
      if (hasFlagMatch && (this.reasonReturn != "" && this.reasonReturn != undefined)) {
          return false;
      } else {
          return true;
      }
    }
  },
};
</script>

<style>
  @media (min-width: 761px) {
    .mobile-version {
      display: none !important;
    }
  }

  @media (max-width: 760px) {
    .desktop-version {
      display: none !important;
    }
  }
</style>